.countries___control {
	height: 45px;
	width: 100%;
    border-color: transparent !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 

	.countries___value-container,
	.countries___indicators {
		height: 45px;

		.countries___placeholder {
			font-family: 'Poppins', sans-serif !important;
			font-size: 0.875rem !important;
			line-height: 1.5rem !important;
            color: #9da3af;
		}

		.countries___input-container {
			height: 35px;

			.countries___input {
				height: 35px !important;
				font-family: 'Poppins', sans-serif !important;
				font-size: 0.875rem !important;
				line-height: 1.5rem !important;
			}
		}
	}
}

.countries___control--is-focused {
    border-color: #359538 !important;
    box-shadow: 0 0 0 1px #359538 !important;
}

.countries___option--is-focused {
    background-color: #35953850 !important;
}